import {createApp} from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import CKEditor from '@ckeditor/ckeditor5-vue';


import VueFeather from 'vue-feather';

import '@/assets/scss/config/corporate/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';

import {createNotivue} from 'notivue'
import '@/assets/scss/notification.css'

const notivue = createNotivue({
    position: 'top-right',
    limit: 4,
    enqueue: true,
    pauseOnHover: false,
    avoidDuplicates: true,
})

import "@/assets/scss/app.scss"

AOS.init({
    easing: 'ease-out-back',
    duration: 200
});

createApp(App)
    .use(notivue)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(CKEditor)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(vClickOutside).mount('#app');
